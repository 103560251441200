import React from "react";
import Logo from '../../assets/images/Planet Logo@2x.png'
import PlaneXProLogo from '../../assets/images/PlanetXPro.svg'

import { useState } from 'react';
const Footer = (props) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className="row footerCompponent FooterbgShadowStyle">
            <section>
                <div>
                    <div class="navbar py-0">
                        <div class="container-fluid">
                            <div className="d-flex justify-content-between align-items-center headermainContainer w-100" style={{background:"white"}}>
                                <div className="d-flex gap-3">
                                    <div className='Copyright'>
                                        <span>Copyright 2024 Planet Ventures Pte. Ltd. All rights reserved.</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex gap-3 align-items-center  position-relative">
                                        <img src={PlaneXProLogo}  className='commonImageStyleplanetXPro' alt="UserProfile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Footer;