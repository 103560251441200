import React, { useEffect, useRef, useState } from "react";
import "./MonthlyUpdates.css";
import MonthlyHeader from "../../components/header/monthly_header/MonthlyHeader";
import { useSelector } from "react-redux";
import { deleteAllCookies } from "../../api_interceptor/api";
import HeaderBarMonthly from "../../components/header/HeaderBarMonthly";
import CustomStepper from "../../components/header/custom-stepper/CustomStepper";
import MonthlyUpdatestable from "./monthly_updates_table/MonthlyUpdatestable";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import KeyPeopleSucessionTable from "./keypeople_sucession_table/KeyPeopleSucessionTable";
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from "../../components/loader/Loader";
import api from '../../api_interceptor/api'
import CompetitionTable from "./competition_table/CompetitionTable";
import MarketTable from "./market_table/MarketTable";
import ActionItemSummary from "./action_Item_summary/ActionItemSummary";
import FinancialPerformance from "./financial_performance/Financial_Performance";
import Hits from "./financial_performance/hits/hits";
import Misses from "./financial_performance/misses/Misses";
import GoodNews from "./clients/good_news/GoodNews";
import BadNews from "./clients/bad_news/BadNews";
import AopClients from "./clients/aop_clients/AopClients";
import AspirationalClients from "./clients/aspirational_clients/AspirationalClients";
import RisksAndDecisions from "./risks_and_decisions/RisksAndDecisions";
import HistoricalFinancialPerformance from "./historical_financial_performance/HistoricalFinancialPerformance";
import AopGraph from "./clients/aop_graph/AopGraph";
import AopClientsNew from "./clients/aop_clients_new/AopClientsNew";
import { isMoment } from "moment";
import OperationsTable from "./operations/OperationsTable";
import FinancialPerformanceYTD from "./financial_performance/financial_performance_ytd/FinancialPerformanceYTD";
import NewClients from "./clients/new_clients/NewClients";
import UpcomingClients from "./clients/upcoming_clients/UpcomingClients";
import LostAndFold from "./clients/lost_and_fold/LostAndFold";
import { useDispatch } from 'react-redux';
import { clearUserDetails, setCompanyIDDetails, setDivisionList, setUserDetails, setUserModuleAccessDetails, setEntityIDDetails } from "../../redux/action/Actions";
import StrategicFocus from "./strategic_focus/StrategicFocus";
import QHSE_Update from "./qhse_update/QHSE_Update";
import PeopleKPI from "./people_updates/PeopleKPI";
import KeyPeopleMovements from "./people_updates/KeyPeopleMovements";
import KeyPeopleInitiaves from "./people_updates/KeyPeopleInitiaves";
import SuccessionPlanning from "./people_updates/SuccessionPlanning";
import MainTable from "./last_mom/MainTable";
import PointsDiscussed from "./last_mom/PointsDiscussed";
import ActionTracker from "./last_mom/ActionTracker";
import DecisionTracker from "./last_mom/DecisionTracker";
import IndustryPainting from "./industry_mapping/IndustryPainting";
import FireProofing from "./industry_mapping/FireProofing";
import LegalUpdate from "./legal_update/LegalUpdate";
import ActionTrackerMRM from "./action_Item_summary/ActionTrackerMRM";
import Legal_KPI from "./legal_update/Legal_KPI";
import QHSE_KPI from "./qhse_update/QHSE_KPI";

import { updateState, formatDateTimeForChat } from "../../common_function/common_function";
import CancelBluIconComment from '../../assets/images/Cancel icon Comments.svg'
import ProfIcon from '../../assets/images/Profile icon.svg'
import SendChatIcon from '../../assets/images/Send chat icon.svg'
import CommementBlue from '../../assets/images/Comment icon blue.svg'
import Footer from '../../components/footer/Footer';




ChartJS.register(ArcElement, Tooltip, Legend);


export default function MonthlyUpdates(props) {
  const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [stepperList, setStepperList] = useState([]);
    const [previewEnabled, setPreviewEnabled] = useState(false);
    const [previewList, setPreviewList] = useState({});
    const logoutHandler = () => {
        deleteAllCookies()
    }
    const userData = useSelector((state) => state.user.userData);
    const company_id = useSelector((state) => state.user.companyID);
    const division_id = useSelector((state) => state.user.divisionID);
    const access_details = useSelector((state) => state.user.access_details);
    const [sortByUpdateMonth, setSortByUpdateMonth] = useState("DESC");
    const [isSetViewHistory, setViewHistory] = useState([]);
    const [ismonth, month] = useState(false);
    const [companyListDetails, setcompanyListDetails] = useState([]);
    const [selectedCompany, setselectedCompany] = useState(null);
    const [selectedDivision, setselectedDivision] = useState(null);
    const [DivisionListDetails, setDivisionListDetails] = useState([]);

    const [monthlyListDetails, setmonthlyListDetails] = useState([]);
    const [monthlyListMRMDetails, setmonthlyListMRMDetails] = useState([]);
    const [EntityList, setEntityList] = useState([]);
    const [entityListDetails, setentityListDetails] = useState([]);
    const [selectedEntity, setselectedEntity] = useState(null);

    const [showStrategicComments, setshowStrategicComments] = useState(false);
    const [StrategicChatDetails, setStrategicChatDetails] = useState([]);
    const [StrategicCommentsText, setStrategicCommentsText] = useState('');
    const [StrategicIDForChatAssignedTo, setStrategicIDForChatAssignedTo] = useState(null);
    const [StrategicIDForChat, setStrategicIDForChat] = useState(null);
    const [StrategicChatDetailsHeader, setStrategicChatDetailsHeader] = useState("");
    
    const [showOpenActionMRMComments, setshowOpenActionMRMComments] = useState(false);
    const [OpenActionMRMChatDetails, setOpenActionMRMChatDetails] = useState([]);
    const [OpenActionMRMCommentsText, setOpenActionMRMCommentsText] = useState('');
    const [OpenActionMRMIDForChatAssignedTo, setOpenActionMRMIDForChatAssignedTo] = useState(null);
    const [OpenActionMRMIDForChat, setOpenActionMRMIDForChat] = useState(null);
    const [OpenActionMRMChatDetailsHeader, setOpenActionMRMChatDetailsHeader] = useState("");
    
    const [showDecisionComments, setshowDecisionComments] = useState(false);
    const [DecisionMRMChatDetails, setDecisionChatDetails] = useState([]);
    const [DecisionCommentsText, setDecisionCommentsText] = useState('');
    const [DecisionIDForChatAssignedTo, setDecisionIDForChatAssignedTo] = useState(null);
    const [DecisionIDForChat, setDecisionIDForChat] = useState(null);
    const [DecisionChatDetailsHeader, setDecisionChatDetailsHeader] = useState("");
    const popupRef = useRef(null);


    useEffect(() => {
        document.title = "Monthly updates";
        getuserDetailsByUserId(userData['UserId'])
        GetAllMasterDetailsMRM(userData['UserId']);

    }, []);
    
   

    const previousUrlRef = useRef(window.location.href);
    const location = useLocation();

    // useEffect(() => {
    //     const currentPath = location.pathname;

    //     if (currentPath !== previousUrlRef.current) {
    //         previousUrlRef.current = currentPath;
    //     }
    // }, [location.pathname]);

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const key1 = queryParams.get('userId');
    //     // const key2 = queryParams.get('key2');
    //     console.log('-----------------------------MONTHLY UPDATES-------------------->>>>PARAMS--------------------->>>',key1);
    //     if(key1){
    //         getuserDetailsByUserId(key1)
    //     }
    //   }, [location.search]);

      useEffect(() => {
        setTimeout(() => {
            setselectedDivision(null)
            getDivisionDetails();
            dispatch(setCompanyIDDetails({
                "value": selectedCompany,
                "label": ""
            }));
        }, 500);
    }, [selectedCompany]);

    useEffect(() => {
        setTimeout(() => {
            setselectedEntity(null)
            dispatch(setEntityIDDetails({
                "value": selectedEntity,
                "label": ""
            }));
        // Check if the current location already includes "minutes-of-meeting"
        if (!location.pathname.includes("minutes-of-meeting")) {
            navigateTo("monthly/minutes-of-meeting");
        }
        else{
            navigateTo("monthly/financial-hits-misses");
            navigateTo("monthly/minutes-of-meeting");

        }
        }, 500);
        }, [selectedEntity]);

        const navigate = useNavigate();

        const navigateTo = (module) => {
        navigate('/' + module);
        };


    useEffect(() => {
        if (selectedEntity) {
            dispatch(setDivisionList(selectedEntity))

            getSubmoduleListMRM();
        }
        else{
            dispatch(setEntityIDDetails([]))
        }
    }, [selectedEntity]);

    useEffect(() => {
            if (selectedDivision) {
                dispatch(setDivisionList(selectedDivision))

                getUserRole()
            }
            else{
                dispatch(setDivisionList([]))
            }
        }, [selectedDivision]);

    const getuserDetailsByUserId = (userID) => {
        
        // dispatch(clearUserDetails());
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-details-by-user-id';
        const req_body = {
            UserId: userID
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                if(response && response.data && response.data.user_data){

                    // dispatch(setUserDetails(response.data.user_data));
                }

                if(response && response.data && response.data.company_list){
                    setcompanyListDetails(response.data.company_list)
                }
                // setStepperList(StepperData)
                setIsLoading(false)
            })
            .catch((error) => {
                // setStepperList(StepperData)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getDivisionDetails = () => {
        
        // dispatch(clearUserDetails());
        console.log('UserDetails --------->>',userData);
        setDivisionListDetails([])
        setselectedDivision(null)
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-divisions';
        const req_body = {
            user_id: userData['UserId'] ,
            company_id: selectedCompany,
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if(response && response.data && response.data.division_list){
                    // setcompanyListDetails(response.data.company_list)
                    setDivisionListDetails(response.data.division_list)
                }
                else{
                    setDivisionListDetails([])
                    setselectedDivision(null)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }



    const getCurrentMonth = () => {
        const currentDate = new Date();
        const options = { month: 'long', year: 'numeric' };
        const formattedMonth = currentDate.toLocaleDateString('en-US', options);
        return formattedMonth;
    };

    const hasUpdateForCurrentMonth = (data) => {
        const currentDate = new Date();
        const options = { month: 'long', year: 'numeric' };
        const formattedMonth = currentDate.toLocaleDateString('en-US', options);
    
        return data.some(item => {
            return item.UpdateMonth === formattedMonth;
        });
    }

    // view history api call if form submission end

    const getUserRole = (div_id) => {
        
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-role';
        const req_body = {
            user_id: userData['UserId'],
            company_id : selectedCompany,
            division_id : selectedDivision
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                if (response && response.data && response.data.module_acccess && (response.data.module_acccess).length > 0) {
                    setIsLoading(false)
                    dispatch(setUserModuleAccessDetails(response.data));
                    getSubmoduleList()

                }
                else{
                    setIsLoading(false)
                    getSubmoduleList()

                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getSubmoduleList = (UID) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-submodule-access';
        const req_body = {
            user_id: userData['UserId'],
            module_name : "Monthly",
            company_id : selectedCompany,
            division_id : selectedDivision
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response &&  response.data && response.data.sub_module_access && (response.data.sub_module_access).length >0 )
                {
                    setStepperList(response.data.sub_module_access)                    
                    setIsLoading(false)
                }
                else{
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const setData = () => {
    }

    const logOutEvent = e =>{
        props.onLogout();
    }

    useEffect(() => {
        document.title = "Monthly updates";
        GetAllMonthlyByUserID(userData['UserId'])
    }, []);

    const GetAllMonthlyByUserID = (userID) => {
        // dispatch(clearUserDetails());
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-all-monthly-by-user-id';
        const req_body = {
            user_id: userID
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                if(response && response.data && response.data.user_data){

                    // dispatch(setUserDetails(response.data.user_data));
                }

                if(response && response.data && response.data.Monthly_List && response.data.Entity_List){
                    setmonthlyListDetails(response.data.Monthly_List)
                    setentityListDetails(response.data.Entity_List)
                }
                // setStepperList(StepperData)
                setIsLoading(false)
            })
            .catch((error) => {
                // setStepperList(StepperData)
                setIsLoading(false)
                console.error(error);
            });
    }

    const GetAllMasterDetailsMRM = (UserId) => {
        
        // dispatch(clearUserDetails());
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-all-master-details-mrm';
        const req_body = {
            UserId: UserId
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if(response && response.data && response.data.user_data){

                    // dispatch(setUserDetails(response.data.user_data));
                }

                if(response && response.data){
                    setmonthlyListMRMDetails(response.data.EntityList)
                }
                // setStepperList(StepperData)
                setIsLoading(false)
            })
            .catch((error) => {
                // setStepperList(StepperData)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getSubmoduleListMRM = (UserId) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-submodule-access-mrm';
        const req_body = {
            user_id: userData['UserId'],
            module_name : "Monthly",
            entity_id : selectedEntity,
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                

                if (response &&  response.data && response.data.sub_module_access && (response.data.sub_module_access).length >0 )
                {
                    dispatch(setUserModuleAccessDetails(response.data));
                    setStepperList(response.data.sub_module_access)                    
                    setIsLoading(false)
                }
                else{
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    //   const getAllMasterDetails = () => {
    //     setIsLoading(true)
    //     
    //     const API_URL = 'api/get-all-master-details';
    //     const req_body = {
    //         UserId: userData['UserId'],
    //     };
    //     api.post(API_URL, req_body, {
    //         'Content-Type': 'application/json'
    //     })
    //     .then((response) => {
            
    //         if (response && response.data) {
               
    //             setIsLoading(false)
    //         }
    //         else {
    //             setIsLoading(false)
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //         console.error(error);
    //     });
    // }

    const openStrategicComment = (StrategicID,assignedUser) => {

        setStrategicIDForChat(StrategicID)
        setStrategicIDForChatAssignedTo(assignedUser)
      
        setIsLoading(true)
        const API_URL = 'api/get-strategic-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            StrategicID : StrategicID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setStrategicChatDetails(response.data.Comments)
                    setStrategicChatDetailsHeader(response.data.StrategicHeader)
                    setshowStrategicComments(true)
                    setStrategicCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowStrategicComments(true)
                    setStrategicCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    

    const AddStrategicComments = (StrategicID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-strategic-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            StrategicID : StrategicID,
            EntityID: access_details['company_division_id'],
            StrategicFocusComment : StrategicCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    setStrategicIDForChat(null)
                    setStrategicIDForChatAssignedTo(null)
                    setshowStrategicComments(false)
                    setStrategicCommentsText('')
                    setStrategicChatDetails([])
                    setIsLoading(false)
                    openStrategicComment(StrategicID)
                    
                }
                else {
                    
                    setStrategicIDForChat(null)
                    setStrategicIDForChatAssignedTo(null)
                    setshowStrategicComments(false)
                    setStrategicCommentsText('')
                    setStrategicChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setStrategicIDForChat(null)
                setStrategicIDForChatAssignedTo(null)
                setshowStrategicComments(false)
                setStrategicCommentsText('')
                setStrategicChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    
    const CloseStrategicComments = () => {
        setStrategicIDForChat(null)
        setStrategicIDForChatAssignedTo(null)
        setshowStrategicComments(false)
        setStrategicCommentsText('')
        setStrategicChatDetails([])
        setStrategicChatDetailsHeader('')

    }


    const openActionTrackerMRMComment = (OpenActionID,assignedUser) => {
        
      
        setOpenActionMRMIDForChat(OpenActionID)
        setOpenActionMRMIDForChatAssignedTo(assignedUser)

        setIsLoading(true)
        const API_URL = 'api/get-open-action-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            OpenActionID : OpenActionID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setOpenActionMRMChatDetails(response.data.Comments)
                    setOpenActionMRMChatDetailsHeader(response.data.MileStoneHeader)
                    setshowOpenActionMRMComments(true)
                    setOpenActionMRMCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowOpenActionMRMComments(true)
                    setOpenActionMRMCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    

   
    const AddOpenActionMRMComments = (OpenActionID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-open-action-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            OpenActionID : OpenActionID,
            EntityID: access_details['company_division_id'],
            OpenActionComment : OpenActionMRMCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    

                    setOpenActionMRMIDForChat(null)
                    setOpenActionMRMIDForChatAssignedTo(null)
                    setshowOpenActionMRMComments(false)
                    setOpenActionMRMCommentsText('')
                    setOpenActionMRMChatDetails([])
                    setIsLoading(false)
                    openActionTrackerMRMComment(OpenActionID)
                    
                }
                else {
                    
                    setOpenActionMRMIDForChat(null)
                    setOpenActionMRMIDForChatAssignedTo(null)
                    setshowOpenActionMRMComments(false)
                    setOpenActionMRMCommentsText('')
                    setOpenActionMRMChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setOpenActionMRMIDForChat(null)
                setOpenActionMRMIDForChatAssignedTo(null)
                setshowOpenActionMRMComments(false)
                setOpenActionMRMCommentsText('')
                setOpenActionMRMChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    
    const CloseOpenActionMRMComments = () => {
        setOpenActionMRMIDForChat(null)
        setOpenActionMRMIDForChatAssignedTo(null)
        setshowOpenActionMRMComments(false)
        setOpenActionMRMCommentsText('')
        setOpenActionMRMChatDetails([])
        setOpenActionMRMChatDetailsHeader('')

    }

    
    const openDecisionComment = (DecisionID,assignedUser) => {
        

        setDecisionIDForChat(DecisionID)
        setDecisionIDForChatAssignedTo(assignedUser)
      
        setIsLoading(true)
        const API_URL = 'api/get-decision-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            DecisionID : DecisionID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setDecisionChatDetails(response.data.Comments)
                    setDecisionChatDetailsHeader(response.data.DecisionHeader)
                    setshowDecisionComments(true)
                    setDecisionCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowDecisionComments(true)
                    setDecisionCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    
 

    const AddDecisionComments = (DecisionID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-Decision-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            DecisionID : DecisionID,
            EntityID: access_details['company_division_id'],
            DecisionComment : DecisionCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    setDecisionIDForChat(null)
                    setDecisionIDForChatAssignedTo(null)
                    setshowDecisionComments(false)
                    setDecisionCommentsText('')
                    setDecisionChatDetails([])
                    setIsLoading(false)
                    openDecisionComment(DecisionID)
                    
                }
                else {
                    
                    setDecisionIDForChat(null)
                    setDecisionIDForChatAssignedTo(null)
                    setshowDecisionComments(false)
                    setDecisionCommentsText('')
                    setDecisionChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setDecisionIDForChat(null)
                setDecisionIDForChatAssignedTo(null)
                setshowDecisionComments(false)
                setDecisionCommentsText('')
                setDecisionChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    const CloseDecisionComments = () => {
        setDecisionIDForChat(null)
        setDecisionIDForChatAssignedTo(null)
        setshowDecisionComments(false)
        setDecisionCommentsText('')
        setDecisionChatDetails([])
        setDecisionChatDetailsHeader('')

    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseStrategicComments(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseOpenActionMRMComments(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseDecisionComments(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
  
    
   

    return (
        <div className="rootContainer">

                {showStrategicComments && (
                <div className="popup">
                    <div ref={popupRef} className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseStrategicComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {StrategicChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                            <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.StrategicFocusComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {StrategicChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="StrategicCommentsText"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={StrategicCommentsText}
                                        onChange={(e) => setStrategicCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddStrategicComments(StrategicIDForChat,StrategicIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}

                {showOpenActionMRMComments && (
                <div className="popup">
                    <div ref={popupRef}  className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseOpenActionMRMComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {OpenActionMRMChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.OpenActionComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {OpenActionMRMChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="Milestonecommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={OpenActionMRMCommentsText}
                                        onChange={(e) => setOpenActionMRMCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddOpenActionMRMComments(OpenActionMRMIDForChat,OpenActionMRMIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}

                {showDecisionComments && (
                <div ref={popupRef}  className="popup">
                    <div className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseDecisionComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {DecisionMRMChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.DecisionComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {DecisionMRMChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="Milestonecommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={DecisionCommentsText}
                                        onChange={(e) => setDecisionCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddDecisionComments(DecisionIDForChat,DecisionIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}


            {/* ----------------------------COMMON COMPONENTS (FIXED)------------------------------ */}
            {isLoading && (<Loader />)}

            
            <div className="header-wrapper">
                <HeaderBarMonthly setselectedEntity={setselectedEntity} selectedEntity={selectedEntity} monthlyListMRMDetails={monthlyListMRMDetails} entityListDetails={entityListDetails} monthlyListDetails={monthlyListDetails} companyListDetails={companyListDetails} setselectedCompany={setselectedCompany} selectedCompany={selectedCompany} 
                onLogout={logOutEvent} onCompChange={setData} setDivisionListDetails={setDivisionListDetails}
                DivisionListDetails ={DivisionListDetails} setselectedDivision = {setselectedDivision} selectedDivision={selectedDivision}
                hide={true} openStrategicComment={openStrategicComment} openActionTrackerMRMComment={openActionTrackerMRMComment} openDecisionComment={openDecisionComment}/>
            </div>
            {stepperList.length >0?<div className="content-wrapper">
                <MonthlyHeader previewEnabled={previewEnabled} ismonth={ismonth}/>
            </div>:null}
            {stepperList.length >0?<div className="content-wrapper-sub">
                {stepperList && stepperList.length >0 ?<CustomStepper previewList={previewList} stepperData={stepperList}/> : null}
            </div>:null}


            {/* -------------------------------STEPPER COMPONENTS--------------------------------- */}
            {
                /* ----------------MONTHLY UPDATES & SUCCESSION PLANNING--------------- */
                location.pathname.includes("succession-planning") ?
                    (
                        stepperList.length >0?<div>
                            {/* <div className="content-wrapper-sub">
                                <MonthlyUpdatestable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <KeyPeopleSucessionTable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div> */}
                            <div className="content-wrapper-sub">
                                <OperationsTable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                        </div>:null
                    )
                    :
                    /* -------------------------COMPETITION & MARKET------------------------- */
                    location.pathname.includes("competition-market") ?
                        (stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                                <CompetitionTable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <MarketTable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>

                        </div>:null)
                    :
                    /* -------------------------OPEN ACTION ITEMS------------------------- */
                    location.pathname.includes("open-actions") ?
                        (stepperList.length >0?<div>
                            {/* <div className="content-wrapper-sub">
                                <ActionItemSummary setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div> */}

                            <div className="content-wrapper-sub">
                                <ActionTrackerMRM setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div> 

                        </div>:null)
                    :
                    /* -------------------------HISTORICAL FINANCE PERFORMANCE------------------------- */
                    location.pathname.includes("historical-financial") ?
                        (stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                                <HistoricalFinancialPerformance setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>

                        </div>:null)
                    :
                    /* -------------------------Financial HITS AND MISSES------------------------- */
                    location.pathname.includes("financial-hits-misses") ?
                        (stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                                <FinancialPerformance setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <FinancialPerformanceYTD setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            {/* <div className="content-wrapper-sub">
                                <div className="row">
                                    <div className="col-6">
                                        <Hits setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                                    </div>
                                    <div className="col-6">
                                        <Misses setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                                    </div>
                                </div>
                            </div> */}
                        </div>:null)
                    :
                    /* -------------------------CLIENTS------------------------- */
                    location.pathname.includes("clients") ?
                        (stepperList.length >0?<div>
                            {/* <div className="content-wrapper-sub">
                                <div className="row">
                                    <div className="col-6">
                                        <GoodNews setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                                    </div>
                                    <div className="col-6">
                                        <BadNews setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper-sub">
                                <AopClients setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <AopClientsNew setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <AspirationalClients setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <AopGraph setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div> */}
                            <div className="content-wrapper-sub">
                                <NewClients setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <LostAndFold setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub">
                                <UpcomingClients setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                        </div>:null)
                    :
                    /* -------------------------RISKS AND DECISIONS------------------------- */
                    location.pathname.includes("risk-decisions") ?
                        (stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                                <RisksAndDecisions setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>                                           
                        </div>:null)
                    :


                    /* ------------------------- Strategic Focus------------------------- */
                    location.pathname.includes("strategic-focus") ?
                        (stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                                <StrategicFocus setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>                                           
                        </div>:null)
                    :

                    /* ------------------------- QHSE Updates------------------------- */
                    location.pathname.includes("qhse") ?
                    (stepperList.length >0?<div>
                        <div className="content-wrapper-sub" style={{borderBottom:'none'}}>
                            <QHSE_KPI setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                        </div>
                        <div className="content-wrapper-sub" style={{borderTop:'none'}}>
                            <QHSE_Update setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                        </div>                                           
                    </div>:null)
                   :

                      /* ------------------------- People Updates------------------------- */
                      location.pathname.includes("people-updates") ?
                      (stepperList.length >0?<div>
                          <div className="content-wrapper-sub">
                              <PeopleKPI setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                          </div>
                          <div className="content-wrapper-sub">
                              <KeyPeopleMovements setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                          </div>  
                          <div className="content-wrapper-sub">
                              <KeyPeopleInitiaves setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                          </div>  
                          <div className="content-wrapper-sub">
                              <SuccessionPlanning setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                          </div>                                           
                      </div>:null)
                     :

                         /* ------------------------- LAST MOM------------------------- */
                         location.pathname.includes("minutes-of-meeting") ?
                         (stepperList.length >0?<div>
                             <div className="content-wrapper-sub">
                                 <MainTable setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                             </div>
                             <div className="content-wrapper-sub">
                                 <PointsDiscussed setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                             </div>  
                             <div className="content-wrapper-sub">
                                 <DecisionTracker setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                             </div>  
                             <div className="content-wrapper-sub">
                                 <ActionTracker setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                             </div>                                           
                         </div>:null)
                        :
                    
                        
                        /* ------------------------- Industry Mapping------------------------- */
                        location.pathname.includes("industry-mapping") ?
                        (stepperList.length >0?
                            <div className="split-container">
                            <div className="content-wrapper-sub-side" style={{ borderRight: 'none', borderTop:'none'}}>
                              <IndustryPainting setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                            <div className="content-wrapper-sub-side" style={{ borderLeft: 'none', borderTop:'none' }}>
                              <FireProofing setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                            </div>
                          </div>
                          
                        :null)
                        :

                          /* ------------------------- Legal Updates------------------------- */
                    location.pathname.includes("legal-updates") ?
                    (stepperList.length >0?<div>
                        <div className="content-wrapper-sub" style={{borderBottom:'none'}}>
                            <Legal_KPI setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                        </div>
                        <div className="content-wrapper-sub" style={{borderTop:'none'}}>
                            <LegalUpdate setPreviewList={setPreviewList} setPreviewEnabled={setPreviewEnabled} ismonth={ismonth}/>
                        </div>                                           
                    </div>:null)
                   :
                    /* ------------------------DEFAULT BLOCK IF API FAILS--------------- */
                    (
                        stepperList.length >0?<div>
                            <div className="content-wrapper-sub">
                            </div>
                        </div>:null
                    )
                    
            }
            {stepperList.length ==0?<div className="norcrdTxt w-100 h-100 d-flex justify-content-center align-items-center" style={{fontSize:'14px'}}>
                {/* Please select entity and division to get monthly updates */}
            </div>:null}
            
            <Footer/>

        </div>
    );
}